export const termoUso = `<p><strong>TERMOS E CONDI&Ccedil;&Otilde;ES GERAIS DE USO E DE COMPRA E VENDA</strong></p>
<p>Este termo e condi&ccedil;&otilde;es gerais de uso e de compra e venda aplicam-se aos servi&ccedil;os prestados pela pessoa jur&iacute;dica 2K servi&ccedil;os Ltda, devidamente registrada sob n&ordm; cnpj 28.546.365/00001-35, email <a href="mailto:sindcalculos@sindc%C3%A1lculos.com.br">sindcalculos@sindcalculos.com.br</a>, com sede em Bras&iacute;lia &ndash; DF</p>
<p>Representada por Francisco Carlos, na qualidade de administrador, CPF n&ordm; 436.722.800-20, CRA/RS 18.412, doravante denominado EDITOR DO APLICATIVO, por meio do aplicativo APP.SINDC&Aacute;LCULOSWEB.</p>
<p><strong>I &ndash; OBJETO</strong></p>
<p>O aplicativo app.sindc&aacute;lculosweb caracteriza-se pela presta&ccedil;&atilde;o dos seguintes servi&ccedil;os, incluindo a venda &agrave; dist&acirc;ncia e por meio eletr&ocirc;nico de produtos e servi&ccedil;os:</p>
<p>- C&aacute;lculos Judiciais</p>
<p><strong>II. DA ACEITA&Ccedil;&Atilde;O DOS TERMOS E CONDI&Ccedil;&Otilde;ES GERAIS DE USO E DE COMPRA E VENDA </strong></p>
<p>Todos aqueles que desejarem ter acesso aos servi&ccedil;os ofertados atrav&eacute;s do aplicativo SindC&aacute;lculosWeb dever&atilde;o, primeiramente, se informar sobre as regras que comp&otilde;em o presente instrumento, as quais ficar&atilde;o dispon&iacute;veis para pronta e ampla consulta, em link direto no pr&oacute;prio aplicativo.</p>
<p>Ao utilizar o aplicativo SindC&aacute;lculosWeb, o usu&aacute;rio aceita integralmente as presentes normas e compromete-se a observ&aacute;-las, sob risco de aplica&ccedil;&atilde;o das penalidades cab&iacute;veis. Antes de iniciar qualquer navega&ccedil;&atilde;o no aplicativo, o usu&aacute;rio dever&aacute; cientificar-se de eventuais modifica&ccedil;&otilde;es ou atualiza&ccedil;&otilde;es ocorridas neste termos.</p>
<p>O usu&aacute;rio poder&aacute; ainda ser avisado por e-mail ou diretamente no pr&oacute;prio aplicativo sobre eventuais mudan&ccedil;as nas presentes normas.</p>
<p>Caso n&atilde;o concorde com quaisquer das regras aqui descritas, o usu&aacute;rio deve, imediatamente, abster-se de utilizar o servi&ccedil;o. Se for de seu interesse, poder&aacute;, ainda, entrar em contato com o servi&ccedil;o de atendimento ao cliente, para apresentar-lhe as suas ressalvas.</p>
<p><strong>III. DA NAVEGA&Ccedil;&Atilde;O</strong></p>
<p>O editor do aplicativo SindC&aacute;lculosWeb se compromete a utilizar todas as solu&ccedil;&otilde;es t&eacute;cnicas &agrave; sua disposi&ccedil;&atilde;o para permitir o acesso ao servi&ccedil;o 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. Entretanto, ele poder&aacute;, a qualquer momento, interromper, limitar ou suspender o acesso ao aplicativo ou a algumas de suas p&aacute;ginas, a fim de realizar atualiza&ccedil;&otilde;es, modifica&ccedil;&otilde;es de conte&uacute;do ou qualquer outra a&ccedil;&atilde;o julgada necess&aacute;ria para o seu bom funcionamento.</p>
<p>Os presentes termos e condi&ccedil;&otilde;es gerais de uso e de compra e venda se aplicam a todas as extens&otilde;es do aplicativo SindC&aacute;lculosWeb em redes sociais ou em comunidades, tanto as j&aacute; existentes, quanto aquelas ainda a serem implementadas.</p>
<p><strong>IV. DA GEST&Atilde;O DO APLICATIVO </strong></p>
<p>Para a boa gest&atilde;o, o editor do aplicativo SindC&aacute;lculosWeb poder&aacute;, a qualquer momento:</p>
<p>a) suspender, interromper ou limitar o acesso a todo ou a parte do aplicativo a uma categoria espec&iacute;fica de internautas;</p>
<p>b) remover toda informa&ccedil;&atilde;o que possa perturbar o funcionamento do aplicativo ou que esteja em conflito com normas de Direito brasileiro ou de Direito internacional;</p>
<p>c) suspender o aplicativo, a fim de realizar atualiza&ccedil;&otilde;es e modifica&ccedil;&otilde;es.</p>
<p><strong>V. DO CADASTRO </strong></p>
<p>Os servi&ccedil;os disponibilizados no aplicativo SindC&aacute;lculosWeb apenas poder&atilde;o ser acessados por pessoas plenamente capazes, conforme o Direito brasileiro. Todos aqueles que n&atilde;o possu&iacute;rem plena capacidade civil - menores de 18 anos n&atilde;o emancipados, pr&oacute;digos, &eacute;brios habituais ou viciados em t&oacute;xicos e pessoas que n&atilde;o puderem exprimir sua vontade, por motivo transit&oacute;rio ou permanente - dever&atilde;o ser devidamente assistidos por seus representantes legais, que se responsabilizar&atilde;o pelo cumprimento das presentes regras.</p>
<p>Ao usu&aacute;rio, ser&aacute; permitido manter apenas uma conta junto ao aplicativo SindC&aacute;lculosWeb. Contas duplicadas ser&atilde;o automaticamente desativadas pelo editor do aplicativo, sem preju&iacute;zo de demais penalidades cab&iacute;veis.</p>
<p>Para o devido cadastramento junto ao servi&ccedil;o, o usu&aacute;rio dever&aacute; fornecer integralmente os dados requeridos. Todas as informa&ccedil;&otilde;es fornecidas pelo usu&aacute;rio devem ser precisas, verdadeiras e atualizadas. Em qualquer caso, o usu&aacute;rio responder&aacute;, em &acirc;mbito c&iacute;vel e criminal, pela veracidade, exatid&atilde;o e autenticidade dos dados informados.</p>
<p>O usu&aacute;rio dever&aacute; fornecer um endere&ccedil;o de e-mail v&aacute;lido, atrav&eacute;s do qual o aplicativo realizar&aacute; todos os contatos necess&aacute;rios. Todas as comunica&ccedil;&otilde;es enviadas para o referido endere&ccedil;o ser&atilde;o consideradas lidas pelo usu&aacute;rio, que se compromete, assim, a consultar regularmente suas mensagens recebidas e a respond&ecirc;-las em prazo razo&aacute;vel.</p>
<p>Ap&oacute;s a confirma&ccedil;&atilde;o de seu cadastro, o usu&aacute;rio possuir&aacute; um login (apelido) e uma senha pessoais, que dever&atilde;o ser por ele utilizados para o acesso a sua conta no aplicativo SindC&aacute;lculosWeb. Estes dados de conex&atilde;o n&atilde;o poder&atilde;o ser informados pelo usu&aacute;rio a terceiros, sendo de sua inteira responsabilidade o uso que deles seja feito. O usu&aacute;rio compromete-se a comunicar imediatamente ao editor do aplicativo quaisquer atividades suspeitas ou inesperadas em sua conta.</p>
<p>N&atilde;o ser&aacute; permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que &eacute; pessoal e intransfer&iacute;vel.</p>
<p>Ser&aacute; automaticamente descadastrado o usu&aacute;rio que descumprir quaisquer das normas contidas no presente instrumento, sendo-lhe vedado realizar nova inscri&ccedil;&atilde;o no aplicativo.</p>
<p>O usu&aacute;rio poder&aacute;, a qualquer tempo e sem necessidade de justifica&ccedil;&atilde;o, requerer o cancelamento de seu cadastro junto ao aplicativo SindC&aacute;lculosWeb. O seu descadastramento ser&aacute; realizado o mais rapidamente poss&iacute;vel.</p>
<p><strong>VI. DAS RESPONSABILIDADES </strong></p>
<p>O editor se responsabilizar&aacute; pelos defeitos ou v&iacute;cios encontrados nos servi&ccedil;os prestados pelo aplicativo SindC&aacute;lculosWeb, desde que tenha lhes dado causa. Defeitos ou v&iacute;cios t&eacute;cnicos ou operacionais originados no pr&oacute;prio sistema do usu&aacute;rio n&atilde;o ser&atilde;o de responsabilidade do editor.</p>
<p>O editor responsabiliza-se apenas pelas informa&ccedil;&otilde;es que foram por ele diretamente divulgadas. Quaisquer informa&ccedil;&otilde;es inclu&iacute;das pelos usu&aacute;rios, tais como em coment&aacute;rios e em perfis pessoais, ser&atilde;o de inteira responsabilidade dos pr&oacute;prios.</p>
<p>O usu&aacute;rio &eacute; respons&aacute;vel, ainda:</p>
<p>a) pela correta utiliza&ccedil;&atilde;o do aplicativo e de seus servi&ccedil;os, prezando pela boa conviv&ecirc;ncia, pelo respeito e pela cordialidade no relacionamento com os demais usu&aacute;rios;</p>
<p>b) pelo cumprimento das regras contidas neste instrumento, bem como normas de Direito nacional e de Direito internacional;</p>
<p>c) pela prote&ccedil;&atilde;o dos dados de acesso &agrave; sua conta (login e senha).</p>
<p>O editor n&atilde;o ser&aacute; respons&aacute;vel:</p>
<p>a) pelas caracter&iacute;sticas intr&iacute;nsecas da internet, principalmente relativas &agrave; confiabilidade e &agrave; proced&ecirc;ncia das informa&ccedil;&otilde;es circulando nesta rede;</p>
<p>b) pelos conte&uacute;dos ou atividades il&iacute;citas praticadas atrav&eacute;s de seu aplicativo.</p>
<p><strong>VII. DOS LINKS EXTERNOS </strong></p>
<p>O aplicativo SindC&aacute;lculosWeb pode conter links externos redirecionando o usu&aacute;rio para outras p&aacute;ginas da internet, sobre os quais o editor n&atilde;o exerce controle. Apesar das verifica&ccedil;&otilde;es pr&eacute;vias e regulares realizadas pelo editor, ele se isenta de qualquer responsabilidade sobre o conte&uacute;do encontrado nestes sites e servi&ccedil;os.</p>
<p>Poder&atilde;o ser inclu&iacute;dos links nas p&aacute;ginas e nos documentos do aplicativo SindC&aacute;lculosWeb, desde que n&atilde;o sirvam para fins comerciais ou publicit&aacute;rios. Esta inclus&atilde;o depender&aacute; de autoriza&ccedil;&atilde;o pr&eacute;via do editor.</p>
<p>N&atilde;o ser&aacute; autorizada a inclus&atilde;o de p&aacute;ginas que divulguem quaisquer tipos de informa&ccedil;&otilde;es il&iacute;citas, violentas, pol&ecirc;micas, pornogr&aacute;ficas, xenof&oacute;bicas, discriminat&oacute;rias ou ofensivas.</p>
<p><strong>VIII. DOS DIREITOS AUTORAIS </strong></p>
<p>A estrutura do aplicativo SindC&aacute;lculosWeb , bem como os textos, os gr&aacute;ficos, as imagens, as fotografias, os sons, os v&iacute;deos e as demais aplica&ccedil;&otilde;es inform&aacute;ticas que o comp&otilde;em s&atilde;o de propriedade do editor e s&atilde;o protegidas pela legisla&ccedil;&atilde;o brasileira e internacional referente &agrave; propriedade intelectual.</p>
<p>Qualquer representa&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, adapta&ccedil;&atilde;o ou explora&ccedil;&atilde;o parcial ou total dos conte&uacute;dos, marcas e servi&ccedil;os propostos pelo aplicativo, por qualquer meio que seja, sem autoriza&ccedil;&atilde;o pr&eacute;via, expressa e escrita do editor, &eacute; estritamente vedada, podendo-se recorrer &agrave;s medidas c&iacute;veis e penais cab&iacute;veis. Est&atilde;o exclu&iacute;dos desta previs&atilde;o apenas os elementos que estejam expressamente designados no aplicativo como livres de direitos autorais.</p>
<p>O acesso n&atilde;o gera para o usu&aacute;rio qualquer direito de propriedade intelectual relativo a elementos do aplicativo, os quais restam sob a propriedade exclusiva do editor.</p>
<p>&Eacute; vedado ao usu&aacute;rio incluir no aplicativo dados que possam modificar o seu conte&uacute;do ou sua apar&ecirc;ncia.</p>
<p><strong>IX. DA POL&Iacute;TICA DE PRIVACIDADE </strong></p>
<p>1. Informa&ccedil;&otilde;es gerais</p>
<p>Essa se&ccedil;&atilde;o cont&eacute;m informa&ccedil;&otilde;es a respeito do tratamento de dados pessoais do usu&aacute;rio, total ou parcialmente, automatizados ou n&atilde;o, realizado pelo aplicativo e que poder&atilde;o ou n&atilde;o serem armazenados. O objetivo &eacute; fornecer orienta&ccedil;&otilde;es a respeito das informa&ccedil;&otilde;es coletadas, os motivos da coleta e como o usu&aacute;rio poder&aacute; atualizar, gerenciar, exportar ou excluir essas informa&ccedil;&otilde;es.</p>
<p>A pol&iacute;tica de privacidade do aplicativo est&aacute; de acordo com a Lei federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei federal n. 13.709, de 14 de agosto de 2018 (Lei de Prote&ccedil;&atilde;o de Dados Pessoais) e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Prote&ccedil;&atilde;o de Dados Pessoais).</p>
<p>Esta pol&iacute;tica de privacidade poder&aacute; ser atualizada em decorr&ecirc;ncia de uma eventual atualiza&ccedil;&atilde;o normativa, raz&atilde;o pela qual se convida o usu&aacute;rio a consultar periodicamente esta se&ccedil;&atilde;o.</p>
<p>2. Direitos do usu&aacute;rio</p>
<p>O aplicativo se compromete a cumprir as normas dispostas pelo Regulamento Geral Europeu de Prote&ccedil;&atilde;o de Dados Pessoais (RGPD), em respeito aos seguintes princ&iacute;pios:</p>
<p>Seus dados pessoais ser&atilde;o processados de forma l&iacute;cita, leal e transparente (licitude, lealdade e transpar&ecirc;ncia);</p>
<p>Seus dados pessoais ser&atilde;o coletados apenas para finalidades determinadas, expl&iacute;citas e leg&iacute;timas, n&atilde;o podendo ser tratados posteriormente de uma forma incompat&iacute;vel com essas finalidades (limita&ccedil;&atilde;o das finalidades);</p>
<p>Seus dados pessoais ser&atilde;o coletados de forma adequada, pertinente e limitada &agrave;s necessidades do objetivo para os quais eles s&atilde;o processados (minimiza&ccedil;&atilde;o dos dados);</p>
<p>Seus dados pessoais ser&atilde;o exatos e atualizados sempre que necess&aacute;rio, de maneira que os dados inexatos sejam apagados ou retificados quando poss&iacute;vel (exatid&atilde;o);</p>
<p>Seus dados pessoais ser&atilde;o conservados de uma forma que permita a identifica&ccedil;&atilde;o dos titulares dos dados apenas durante o per&iacute;odo necess&aacute;rio para as finalidades para as quais s&atilde;o tratados (limita&ccedil;&atilde;o da conserva&ccedil;&atilde;o);</p>
<p>Seus dados pessoais ser&atilde;o tratados de forma segura, protegidos do tratamento n&atilde;o autorizado ou il&iacute;cito e contra a sua perda, destrui&ccedil;&atilde;o ou danifica&ccedil;&atilde;o acidental, adotando as medidas t&eacute;cnicas ou organizativas adequadas (integridade e confidencialidade).</p>
<p>O usu&aacute;rio do aplicativo possui os seguintes direitos, conferidos pela Lei federal n. 13.709, de 14 de agosto de 2018 (Lei de Prote&ccedil;&atilde;o de Dados Pessoais) e pelo Regulamento Geral Europeu de Prote&ccedil;&atilde;o de Dados Pessoais (RGPD):</p>
<p>Direito de confirma&ccedil;&atilde;o e acesso: &eacute; o direito do usu&aacute;rio de obter do aplicativo a confirma&ccedil;&atilde;o de que os dados pessoais que lhe digam respeito s&atilde;o ou n&atilde;o objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;</p>
<p>Direito de retifica&ccedil;&atilde;o: &eacute; o direito do usu&aacute;rio de obter do aplicativo , sem demora injustificada, a retifica&ccedil;&atilde;o dos dados pessoais inexatos que lhe digam respeito;</p>
<p>Direito &agrave; elimina&ccedil;&atilde;o dos dados (direito ao esquecimento): &eacute; o direito do usu&aacute;rio de ter seus dados apagados do aplicativo ;</p>
<p>Direito &agrave; limita&ccedil;&atilde;o do tratamento dos dados: &eacute; o direito do usu&aacute;rio de limitar o tratamento de seus dados pessoais, podendo obt&ecirc;-la quando contesta a exatid&atilde;o dos dados, quando o tratamento for il&iacute;cito, quando o aplicativo n&atilde;o precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecess&aacute;rios;</p>
<p>Direito de oposi&ccedil;&atilde;o: &eacute; o direito do usu&aacute;rio de, a qualquer momento, se opor por motivos relacionados com a sua situa&ccedil;&atilde;o particular, ao tratamento dos dados pessoais que lhe digam respeito, podendo se opor ainda ao uso de seus dados pessoais para defini&ccedil;&atilde;o de perfil de marketing (profiling);</p>
<p>Direito de portabilidade dos dados: &eacute; o direito do usu&aacute;rio de receber os dados pessoais que lhe digam respeito e que tenha fornecido ao aplicativo , num formato estruturado, de uso corrente e de leitura autom&aacute;tica, e o direito de transmitir esses dados a outro aplicativo ;</p>
<p>Direito de n&atilde;o ser submetido a decis&otilde;es automatizadas: &eacute; o direito do usu&aacute;rio de n&atilde;o ficar sujeito a nenhuma decis&atilde;o tomada exclusivamente com base no tratamento automatizado, incluindo a defini&ccedil;&atilde;o de perfis (profiling), que produza efeitos na sua esfera jur&iacute;dica ou que o afete significativamente de forma similar.</p>
<p>O usu&aacute;rio poder&aacute; exercer os seus direitos por meio de comunica&ccedil;&atilde;o escrita enviada ao aplicativo com o assunto , especificando:</p>
<p>Nome completo ou raz&atilde;o social, n&uacute;mero do CPF (Cadastro de Pessoas F&iacute;sicas, da Receita Federal do Brasil) ou CNPJ (Cadastro Nacional de Pessoa Jur&iacute;dica, da Receita Federal do Brasil) e endere&ccedil;o de e-mail do usu&aacute;rio e, se for o caso, do seu representante;</p>
<p>Direito que deseja exercer junto ao aplicativo ;</p>
<p>Data do pedido e assinatura do usu&aacute;rio;</p>
<p>Todo documento que possa demonstrar ou justificar o exerc&iacute;cio de seu direito.</p>
<p>O pedido dever&aacute; ser enviado ao e-mail <u><a href="mailto:sac@sindcalculos.com.br">sac@sindcalculos.com.br</a></u></p>
<p>O usu&aacute;rio ser&aacute; informado em caso de retifica&ccedil;&atilde;o ou elimina&ccedil;&atilde;o dos seus dados.</p>
<p>3. Informa&ccedil;&otilde;es coletadas</p>
<p>3.1. Tipo de dados coletados</p>
<p>Os dados pessoais coletados pelo aplicativo s&atilde;o apenas aqueles necess&aacute;rios &agrave; identifica&ccedil;&atilde;o do usu&aacute;rio, n&atilde;o sendo coletados dados sens&iacute;veis do usu&aacute;rio, definidos nos artigos 9&ordm; e 10 do Regulamento Geral Europeu de Prote&ccedil;&atilde;o de Dados Pessoais (RGPD) e do artigo 11 da Lei federal n. 13.709, de 14 de agosto de 2018 .</p>
<p>Ser&atilde;o coletados todos os dados necess&aacute;rios ao cadastro do usu&aacute;rio, como nome completo ou raz&atilde;o social, n&uacute;mero do CPF ou CNPJ, e-mail e endere&ccedil;o do usu&aacute;rio, requisitos necess&aacute;rios para acessar determinados servi&ccedil;os oferecidos pelo aplicativo .</p>
<p>Ainda, ser&atilde;o coletados os dados necess&aacute;rios para a execu&ccedil;&atilde;o do contrato de venda ou presta&ccedil;&atilde;o de servi&ccedil;os eventualmente conclu&iacute;do entre o aplicativo e o usu&aacute;rio, referente aos servi&ccedil;os oferecidos, sem os quais n&atilde;o ser&aacute; poss&iacute;vel a sua execu&ccedil;&atilde;o.</p>
<p>Eventualmente poder&atilde;o ser coletados outras categorias de dados, desde que fornecidos com o consentimento do usu&aacute;rio, ou ainda em raz&atilde;o de interesse leg&iacute;timo ou demais motivos permitidos em lei.</p>
<p>O usu&aacute;rio se compromete a fornecer exclusivamente os seus dados pessoais e n&atilde;o os de terceiros.</p>
<p>3.2. Fundamento jur&iacute;dico para o tratamento dos dados pessoais</p>
<p>Ao utilizar os servi&ccedil;os aplicativo , o usu&aacute;rio est&aacute; consentindo com o presente termo e com a sua pol&iacute;tica de privacidade.</p>
<p>O usu&aacute;rio tem o direito de retirar o seu consentimento a qualquer momento, n&atilde;o comprometendo a licitude dos dados pessoais tratados antes de sua retirada. A retirada do consentimento poder&aacute; ser feita pelo e-mail: <u><a href="mailto:suporte@sindcalculos.com.br">suporte@sindcalculos.com.br</a></u> ou por correio enviado ao seguinte endere&ccedil;o:</p>
<p>O consentimento dos relativamente ou absolutamente incapazes, especialmente de crian&ccedil;as menores de 16 (dezesseis) anos, apenas poder&aacute; ser feito, respectivamente, se devidamente assistidos ou representados.</p>
<p>Poder&atilde;o ainda serem coletados dados pessoais necess&aacute;rios para a execu&ccedil;&atilde;o e cumprimento dos servi&ccedil;os contratados pelo usu&aacute;rio no aplicativo .</p>
<p>O tratamento de dados pessoais sem o consentimento do usu&aacute;rio apenas ser&aacute; realizado em raz&atilde;o de interesse leg&iacute;timo ou para as hip&oacute;teses previstas em lei, dentre outras, as seguintes:</p>
<p>para o cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria pelo controlador;</p>
<p>para a realiza&ccedil;&atilde;o de estudos de pesquisas internas, garantida, sempre que poss&iacute;vel, a anonimiza&ccedil;&atilde;o dos dados pessoais;</p>
<p>quando necess&aacute;rio para a execu&ccedil;&atilde;o de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o usu&aacute;rio, a pedido do titular dos dados;</p>
<p>para o exerc&iacute;cio regular de direitos em processo judicial, administrativo ou arbitral, esse &uacute;ltimo nos termos da Lei n&ordm; 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</p>
<p>para a prote&ccedil;&atilde;o da vida ou da incolumidade f&iacute;sica do titular dos dados ou de terceiro;</p>
<p>para a tutela da sa&uacute;de, em procedimento realizado por profissionais da &aacute;rea da sa&uacute;de ou por entidades sanit&aacute;rias;</p>
<p>quando necess&aacute;rio para atender aos interesses leg&iacute;timos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a prote&ccedil;&atilde;o dos dados pessoais;</p>
<p>para a prote&ccedil;&atilde;o do cr&eacute;dito, inclusive quanto ao disposto na legisla&ccedil;&atilde;o pertinente.</p>
<p>3.3. Finalidades do tratamento dos dados pessoais</p>
<p>Os dados pessoas do usu&aacute;rio coletados pelo aplicativo t&ecirc;m por finalidade facilitar, agilizar e cumprir os compromissos estabelecidos com o usu&aacute;rio e a fazer cumprir as solicita&ccedil;&otilde;es realizadas por meio do preenchimento de formul&aacute;rios.</p>
<p>Os dados pessoais poder&atilde;o ser utilizados tamb&eacute;m com uma finalidade comercial, para personalizar o conte&uacute;do oferecido ao usu&aacute;rio, bem como para dar subs&iacute;dio ao aplicativo para a melhora da qualidade e funcionamento de seus servi&ccedil;os.</p>
<p>O aplicativo recolhe os dados do usu&aacute;rio para que seja realizado defini&ccedil;&atilde;o de perfis (profiling), ou seja, tratamento automatizado de dados pessoais que consista em utilizar esses dados para avaliar certos aspetos pessoais do usu&aacute;rio, principalmente para analisar ou prever aspectos relacionados com o seu desempenho profissional, a sua situa&ccedil;&atilde;o econ&ocirc;mica, sa&uacute;de, prefer&ecirc;ncias pessoais, interesses, fiabilidade, comportamento, localiza&ccedil;&atilde;o ou deslocamentos.</p>
<p>Os dados de cadastro ser&atilde;o utilizados para permitir o acesso ao usu&aacute;rio de determinados conte&uacute;dos do aplicativo exclusivos para usu&aacute;rios cadastrados.</p>
<p>Por fim, ser&atilde;o coletados dados necess&aacute;rios para a execu&ccedil;&atilde;o do contrato de venda ou presta&ccedil;&atilde;o de servi&ccedil;os eventualmente conclu&iacute;do entre o aplicativo e o usu&aacute;rio, referente aos servi&ccedil;os oferecidos.</p>
<p>O aplicativo n&atilde;o possui a inten&ccedil;&atilde;o de tratar os dados pessoais do usu&aacute;rio para outras finalidades.</p>
<p>3.4. Prazo de conserva&ccedil;&atilde;o dos dados pessoais</p>
<p>Os dados pessoais do usu&aacute;rio ser&atilde;o conservados por um per&iacute;odo n&atilde;o superior ao exigido para cumprir os objetivos em raz&atilde;o dos quais eles s&atilde;o processados.</p>
<p>O per&iacute;odo de conserva&ccedil;&atilde;o dos dados s&atilde;o definidos de acordo com os seguintes crit&eacute;rios:</p>
<p>- acesso do usu&aacute;rio as informa&ccedil;&otilde;es pertinentes &ndash; seus c&aacute;lculos</p>
<p>Os dados pessoais dos usu&aacute;rios apenas poder&atilde;o ser conservados ap&oacute;s o t&eacute;rmino de seu tratamento nas seguintes hip&oacute;teses:</p>
<p>para o cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria pelo controlador;</p>
<p>para estudo por &oacute;rg&atilde;o de pesquisa interna, garantida, sempre que poss&iacute;vel, a anonimiza&ccedil;&atilde;o dos dados pessoais;</p>
<p>para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</p>
<p>3.5. Destinat&aacute;rios e transfer&ecirc;ncia dos dados pessoais</p>
<p>Os dados pessoais do usu&aacute;rio n&atilde;o ser&atilde;o compartilhadas com terceiros, que ser&atilde;o tratados apenas por este aplicativo .</p>
<p>4. Do tratamento dos dados pessoais</p>
<p>4.1. Do respons&aacute;vel pelo tratamento dos dados (data controller)</p>
<p>O controlador, respons&aacute;vel pelo tratamento dos dados pessoais do usu&aacute;rio, &eacute; a pessoa f&iacute;sica ou jur&iacute;dica, a autoridade p&uacute;blica, a ag&ecirc;ncia ou outro organismo que, individualmente ou em conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais.</p>
<p>Neste aplicativo , o respons&aacute;vel pelo tratamento dos dados pessoais coletados &eacute; a empresa 2K Servi&ccedil;os Ltda , representada por seu administrador, que poder&aacute; ser contactado pelo e-mail: <u><a href="mailto:sac@sindcalculos.com.br">sac@sindcalculos.com.br</a></u> / <u><a href="mailto:suporte@sindcalculos.com.br">suporte@sindcalculos.com.br</a></u></p>
<p>O respons&aacute;vel pelo tratamento dos dados se encarregar&aacute; diretamente do tratamento dos dados pessoais do usu&aacute;rio.</p>
<p>4.2. Do encarregado de prote&ccedil;&atilde;o de dados (data protection officer)</p>
<p>O encarregado de prote&ccedil;&atilde;o de dados (data protection officer) &eacute; o profissional encarregado de informar, aconselhar e controlar o respons&aacute;vel pelo tratamento dos dados , bem como os trabalhadores que tratem os dados, a respeito das obriga&ccedil;&otilde;es do aplicativo nos termos do Regulamento Geral Europeu de Prote&ccedil;&atilde;o de Dados (RGDP), da Lei federal n. 13.709, de 14 de agosto de 2018 (Lei de Prote&ccedil;&atilde;o de Dados Pessoais) e de outras disposi&ccedil;&otilde;es de prote&ccedil;&atilde;o de dados presentes na legisla&ccedil;&atilde;o nacional e internacional, em coopera&ccedil;&atilde;o com a autoridade de controle competente.</p>
<p>Neste aplicativo o encarregado de prote&ccedil;&atilde;o de dados (data protection officer) &eacute; a pr&oacute;pria empresa e poder&aacute; ser contactado pelo e-mail: administrador@sindcalculos.com.br .</p>
<p>5. Seguran&ccedil;a no tratamento dos dados pessoais do usu&aacute;rio</p>
<p>O aplicativo se compromete a aplicar as medidas t&eacute;cnicas e organizativas adequadas para assegurar um n&iacute;vel de seguran&ccedil;a adequado ao risco, tendo em conta as t&eacute;cnicas mais avan&ccedil;adas, os custos de aplica&ccedil;&atilde;o e a natureza, o &acirc;mbito, o contexto e as finalidades do tratamento, bem como os riscos, de probabilidade e gravidade vari&aacute;vel, para os direitos e liberdades do usu&aacute;rio.</p>
<p>Para tanto, o aplicativo utiliza certificado SSL (Secure Socket Layer) que garante que os dados pessoais se transmitam de forma segura e confidencial, de maneira que a transmiss&atilde;o dos dados entre o servidor e o usu&aacute;rio, e em retroalimenta&ccedil;&atilde;o, ocorra de maneira totalmente cifrada ou encriptada.</p>
<p>No entanto, o aplicativo se exime da responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usu&aacute;rio, que, por exemplo, transfere seus dados a terceiro, exceto se a pirataria se deu em raz&atilde;o de falha de seguran&ccedil;a do aplicativo . O aplicativo se compromete, ainda, a comunicar o usu&aacute;rio em prazo adequado caso ocorra algum tipo de viola&ccedil;&atilde;o da seguran&ccedil;a de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.</p>
<p>A viola&ccedil;&atilde;o de dados pessoais &eacute; uma viola&ccedil;&atilde;o da seguran&ccedil;a que provoque, de modo acidental ou il&iacute;cito, a destrui&ccedil;&atilde;o, a perda, a altera&ccedil;&atilde;o, a divulga&ccedil;&atilde;o ou o acesso, n&atilde;o autorizados, a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.</p>
<p>Por fim, o aplicativo se compromete a tratar os dados pessoais do usu&aacute;rio com confidencialidade, dentro dos limites legais.</p>
<p>6 . Reclama&ccedil;&atilde;o a uma autoridade de controle</p>
<p>Sem preju&iacute;zo de qualquer outra via de recurso administrativo ou judicial, todos os titulares de dados t&ecirc;m direito a apresentar reclama&ccedil;&atilde;o a uma autoridade de controle. A reclama&ccedil;&atilde;o poder&aacute; ser feita &agrave; autoridade da sede do aplicativo, do pa&iacute;s de resid&ecirc;ncia habitual do usu&aacute;rio, do seu local de trabalho ou do local onde foi alegadamente praticada a infra&ccedil;&atilde;o.</p>
<p><strong>X. DAS OFERTAS E PRODUTOS </strong></p>
<p>Por interm&eacute;dio do aplicativo SindC&aacute;lculosWeb, o editor fornece ao cliente um cat&aacute;logo ou uma loja online apresentando com exatid&atilde;o os produtos e servi&ccedil;os vendidos. Os produtos e servi&ccedil;os est&atilde;o descritos e apresentados com o maior grau de precis&atilde;o poss&iacute;vel, contendo informa&ccedil;&otilde;es corretas, claras, precisas, ostensivas e em l&iacute;ngua portuguesa sobre suas caracter&iacute;sticas, qualidades, quantidade, composi&ccedil;&atilde;o, pre&ccedil;o, garantia, prazos de validade e origem, entre outros dados, bem como sobre os riscos que apresentam &agrave; sa&uacute;de e seguran&ccedil;a do usu&aacute;rio.</p>
<p>Os produtos e servi&ccedil;os s&atilde;o colocados &agrave; venda at&eacute; o limite do estoque dispon&iacute;vel.</p>
<p>Os pre&ccedil;os e as taxas referentes &agrave; venda dos produtos e servi&ccedil;os est&atilde;o precisadas no cat&aacute;logo ou na loja online.</p>
<p>Antes de finalizar a compra sobre determinado produto ou servi&ccedil;o, o usu&aacute;rio dever&aacute; se informar sobre as suas especifica&ccedil;&otilde;es e sobre a sua destina&ccedil;&atilde;o, bem como a faixa et&aacute;ria para a qual &eacute; recomendado. Em se tratando de produto ou servi&ccedil;o adquirido no &acirc;mbito de ofertas, o usu&aacute;rio dever&aacute; tamb&eacute;m observar as condi&ccedil;&otilde;es de aplica&ccedil;&atilde;o destas.</p>
<p>As ofertas ser&atilde;o disponibilizadas no aplicativo de acordo com a conveni&ecirc;ncia do editor. Elas poder&atilde;o ser conclu&iacute;das antes do prazo previsto, se o estoque for finalizado e n&atilde;o for poss&iacute;vel restabelec&ecirc;-lo com os fornecedores do aplicativo.</p>
<p><strong>XI. DOS PRE&Ccedil;OS </strong></p>
<p>O vendedor se reserva o direito de modificar os pre&ccedil;os dos produtos e servi&ccedil;os a qualquer momento, publicando-os no aplicativo SindC&aacute;lculosWeb .</p>
<p>Ser&atilde;o aplicadas as tarifas em vigor no momento do pedido, sob reserva de disponibilidade de produtos e servi&ccedil;os nesta data.</p>
<p>Os pre&ccedil;os ser&atilde;o indicados em reais e n&atilde;o incluir&atilde;o as taxas de entrega, as quais vir&atilde;o especificadas &agrave; parte e ser&atilde;o informadas ao cliente antes da realiza&ccedil;&atilde;o do pedido.</p>
<p>O montante total do pedido, incluindo todas as taxas, ser&aacute; indicado antes da valida&ccedil;&atilde;o final do pedido.</p>
<p><strong>XII. DO PAGAMENTO </strong></p>
<p>O pagamento do pedido dever&aacute; ser realizado &agrave; vista, atrav&eacute;s dos seguintes meios:</p>
<p>Cart&atilde;o de Cr&eacute;dito /Dep&oacute;sito banc&aacute;rio ou Boleto</p>
<p><strong>XIII. DA ENTREGA </strong></p>
<p>O prazo de entrega dos produtos ou dos servi&ccedil;os adquiridos no aplicativo SindC&aacute;lculosWeb ser&aacute; informado no momento do pedido, em dias &uacute;teis. O tempo para a entrega &eacute; calculado conforme o estoque, a regi&atilde;o, o processo de emiss&atilde;o da nota fiscal e a prepara&ccedil;&atilde;o do pedido.</p>
<p>Ap&oacute;s a finaliza&ccedil;&atilde;o do pedido, n&atilde;o poder&aacute; ser poss&iacute;vel alterar a forma de pagamento, o endere&ccedil;o de entrega ou as condi&ccedil;&otilde;es de entrega do produto, tais como prioridade ou adiantamento.</p>
<p><strong>XIV. DAS TROCAS E DEVOLU&Ccedil;&Otilde;ES </strong></p>
<p>A pol&iacute;tica de trocas e de devolu&ccedil;&otilde;es do aplicativo SindC&aacute;lculosWeb ser&aacute; regida conforme o C&oacute;digo de Defesa do Consumidor .</p>
<p>Afora a hip&oacute;tese de arrependimento do cliente, a troca ou a devolu&ccedil;&atilde;o dos produtos ou dos servi&ccedil;os adquiridos apenas ser&aacute; realizada mediante verifica&ccedil;&atilde;o de v&iacute;cios de qualidade ou quantidade que os tornem impr&oacute;prios ou inadequados ao consumo a que se destinam ou que lhes diminuam o valor. Tamb&eacute;m poder&atilde;o ser trocados ou devolvidos os produtos ou servi&ccedil;os que apresentem disparidade com as indica&ccedil;&otilde;es constantes do recipiente, da embalagem, rotulagem ou mensagem publicit&aacute;ria, respeitadas as varia&ccedil;&otilde;es decorrentes de sua natureza.</p>
<p>O usu&aacute;rio dever&aacute; comunicar o vendedor, por meio de seu servi&ccedil;o de atendimento, t&atilde;o logo constate o v&iacute;cio no produto ou servi&ccedil;o adquirido. Se, no prazo m&aacute;ximo de 30 (trinta) dias, n&atilde;o for poss&iacute;vel resolver o v&iacute;cio ou, independente deste prazo, a substitui&ccedil;&atilde;o das partes viciadas puder comprometer a qualidade ou caracter&iacute;sticas do produto ou servi&ccedil;o, diminuir-lhe o valor ou se tratar de produto ou servi&ccedil;o essencial, o cliente poder&aacute; optar pela substitui&ccedil;&atilde;o do produto por outro de mesma esp&eacute;cie ou pela reexecu&ccedil;&atilde;o do servi&ccedil;o, pela devolu&ccedil;&atilde;o da quantia paga ou pelo abatimento proporcional do pre&ccedil;o.</p>
<p><strong>XV. DO SERVI&Ccedil;O DE ATENDIMENTO AO USU&Aacute;RIO </strong></p>
<p>Em caso de d&uacute;vidas, sugest&otilde;es ou problemas com a utiliza&ccedil;&atilde;o do aplicativo SindC&aacute;lculosWeb , o usu&aacute;rio poder&aacute; contatar diretamente o seu servi&ccedil;o de atendimento, atrav&eacute;s do endere&ccedil;o de e-mail: sac@sindcalculos.com.br , bem como do telefone:. Estes servi&ccedil;os de atendimento ao usu&aacute;rio estar&atilde;o dispon&iacute;veis nos seguintes dias e hor&aacute;rios:</p>
<p>De segunda a sexta feira - Das 8h30 as 18h</p>
<p>O usu&aacute;rio poder&aacute;, ainda, optar por enviar correspond&ecirc;ncia ao endere&ccedil;o da sede do aplicativo SindC&aacute;lculosWeb , informado no in&iacute;cio deste documento.</p>
<p><strong>XVI. DAS SAN&Ccedil;&Otilde;ES </strong></p>
<p>Sem preju&iacute;zo das demais medidas legais cab&iacute;veis, o editor do aplicativo SindC&aacute;lculosWeb poder&aacute;, a qualquer momento, advertir, suspender ou cancelar a conta do usu&aacute;rio:</p>
<p>a) que descumprir quaisquer dos dispositivos contidos no presente instrumento;</p>
<p>b) que descumprir os seus deveres de usu&aacute;rio;</p>
<p>c) que praticar atos fraudulentos ou dolosos;</p>
<p>d) cujo comportamento constitua ou possa vir a importar ofensa ou dano a terceiro ou ao pr&oacute;prio aplicativo.</p>
<p><strong>XVII. DAS ALTERA&Ccedil;&Otilde;ES </strong></p>
<p><a name="_GoBack"></a> A presente vers&atilde;o dos termos e condi&ccedil;&otilde;es gerais de uso e de compra e venda foi atualizada pela &uacute;ltima vez em: 08/09/2020 .</p>
<p>O editor se reserva o direito de modificar, a qualquer momento o aplicativo e os servi&ccedil;os, bem como as presentes normas, especialmente para adapt&aacute;-las &agrave;s evolu&ccedil;&otilde;es do aplicativo SindC&aacute;lculosWeb , seja pela disponibiliza&ccedil;&atilde;o de novas funcionalidades, seja pela supress&atilde;o ou modifica&ccedil;&atilde;o daquelas j&aacute; existentes.</p>
<p>O usu&aacute;rio ser&aacute; explicitamente notificado em caso de altera&ccedil;&atilde;o do presente termo.</p>
<p>Seja bem-vindo(a)</p>
<p>A equipe do aplicativo SindC&aacute;lculosWeb lhe deseja uma excelente navega&ccedil;&atilde;o!</p>`;
