<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <md-card>
            <md-card-header
              class="md-card-header-icon md-card-header-green"
              v-show="confirmacao"
            >
              <div class="card-icon">
                <md-icon>mail</md-icon>
              </div>
              <h4 class="title text-center">Confirmação de Email</h4>
            </md-card-header>

            <md-card-content v-show="confirmacao">
              <h4>
                Email Confirmado !
              </h4>
            </md-card-content>

            <md-card-actions v-show="confirmacao">
              <md-button
                type="button"
                class="md-success small-alert-modal"
                @click="continuar"
                >Continuar
              </md-button>
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { Modal } from "@/components";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import * as storage from "../storage";
import Vue from "vue";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    token: {
      type: String
    }
  },
  created() {
    storage.deleteLocalToken();
    //storage.setLocalToken();
  },
  data() {
    return {
      tokenUrl: this.token,
      confirmacao: false,
      mensagem: ""
    };
  },
  mounted() {
    this.ActionValidarToken(this.tokenUrl).then(response => {
      if (response.status !== 200) {
        this.$router.push({ name: "login" });
      } else {
        this.confirmarEmail().then();
      }
    });
  },
  methods: {
    ...mapActions("auth", [
      "ActionLoadSession",
      "ActionValidarToken",
      "ActionSetToken",
      "ActionConfirmarEmail"
    ]),
    async confirmarEmail() {
      try {
        let token = this.tokenUrl;
        await this.ActionConfirmarEmail({ token }).then(response => {
          if (response.status === 200) {
            this.mensagem = response.body.mensagem;
            if (!response.body.mensagem.includes("Erro")) {
              if (response.body.mensagem.includes("não encontrado")) {
                Swal.fire({
                  //title: "Sucesso",
                  icon: "warning",
                  text: this.mensagem,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              } else {
                Swal.fire({
                  //title: "Sucesso",
                  icon: "success",
                  text: this.mensagem,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false
                });
                this.confirmacao = true;
              }
              this.ActionSetToken(response.body.jwt).then(() => {
                this.ActionLoadSession().then(() => {
                  window._Vue.$router.push({ name: "Home" });
                });
              });
            } else {
              Swal.fire({
                title: "Erro!",
                icon: "error",
                text: this.mensagem,
                customClass: {
                  confirmButton: "md-button md-danger btn-fill"
                },
                buttonsStyling: false
              });
            }
          }
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    continuar() {
      window._Vue.$router.push({ name: "Home" });
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 400) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  color: red;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.md-field label {
  left: 25px;
}

#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
</style>
