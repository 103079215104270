<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>list_alt</md-icon>
              </div>
              <h3 class="title text-center">
                Aceite nossos Termos e Condições para Continuar
              </h3>
            </md-card-header>

            <md-card-content>
              <p><span v-html="termos.text"></span></p>
            </md-card-content>

            <md-card-actions>
              <div class="md-layout-item">
                <ValidationProvider
                  name="Termos e Condições"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <md-checkbox
                    v-show="showCheck"
                    v-model="termosCondicoes"
                    :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >Estou de acordo com os termos e condições.
                  </md-checkbox>
                  <br />
                  <slide-y-down-transition>
                    <span
                      v-show="failed"
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </slide-y-down-transition>
                </ValidationProvider>
              </div>
              <md-button
                type="submit"
                class="md-success small-alert-modal"
                :disabled="checkEditionDisabled()"
                >Continuar
              </md-button>
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { SlideYDownTransition } from "vue2-transitions";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { Modal } from "@/components";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { termoUso } from "@/pages/Dashboard/js/termoUso";

import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import * as storage from "../storage";
import Vue from "vue";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: {
    SlideYDownTransition
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      showCheck: true,
      isDisabledEdition: true,
      termosCondicoes: false,
      mensagem: "",
      termos: {
        text: termoUso
      }
    };
  },
  methods: {
    ...mapActions("user", ["ActionPUTUsuarioPerfil"]),
    ...mapActions("auth", ["ActionLoadSession"]),
    async submit() {
      try {
        if (this.user_profile_state.termosCondicoes === "1") {
          this.continuar();
        } else {
          let form = Object.assign({}, this.user_profile_state);
          form.termosCondicoes = "1";
          let id = form["idUsuario"];
          let papel = form["papel"];
          form["nomePapel"] = form["papel"];
          delete form.papel;
          delete form.dataCriacao;
          await this.ActionPUTUsuarioPerfil({ id, papel, form }).then(
            response => {
              if (response.status === 200) {
                this.mensagem = response.body.mensagem;
                if (!response.body.mensagem.includes("Erro")) {
                  if (response.body.mensagem.includes("não encontrado")) {
                    Swal.fire({
                      //title: "Sucesso",
                      icon: "warning",
                      text: this.mensagem,
                      customClass: {
                        confirmButton: "md-button md-success btn-fill"
                      },
                      buttonsStyling: false
                    });
                  } else {
                    Swal.fire({
                      //title: "Sucesso",
                      icon: "success",
                      text: this.mensagem,
                      customClass: {
                        confirmButton: "md-button md-success btn-fill"
                      },
                      buttonsStyling: false
                    });
                  }
                  this.ActionLoadSession().then(() => {
                    this.continuar();
                  });
                } else {
                  Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    text: this.mensagem,
                    customClass: {
                      confirmButton: "md-button md-danger btn-fill"
                    },
                    buttonsStyling: false
                  });
                }
              }
            }
          );
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    continuar() {
      window._Vue.$router.push({ name: "Home" }).catch(err => {});
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 400) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  },
  mounted() {
    if (this.user_profile_state.termosCondicoes === "1") {
      this.termosCondicoes = true;
      this.showCheck = false;
    }
  },
  watch: {
    termosCondicoes: function(val, oldVal) {
      if (val) {
        this.isDisabledEdition = false;
      } else {
        this.isDisabledEdition = true;
        this.showButton = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}
.md-card {
  height: 100%;
  width: 100%;
}
.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  color: red;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-checkbox .md-checkbox-label {
  color: #000;
}

.md-field label {
  left: 25px;
}

#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
</style>
